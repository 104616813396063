exports.components = {
  "component---plugins-gatsby-theme-events-section-src-pages-events-js": () => import("./../../../plugins/gatsby-theme-events-section/src/pages/events.js" /* webpackChunkName: "component---plugins-gatsby-theme-events-section-src-pages-events-js" */),
  "component---plugins-gatsby-theme-events-section-src-templates-event-js": () => import("./../../../plugins/gatsby-theme-events-section/src/templates/event.js" /* webpackChunkName: "component---plugins-gatsby-theme-events-section-src-templates-event-js" */),
  "component---plugins-gatsby-theme-sample-page-src-pages-sample-js": () => import("./../../../plugins/gatsby-theme-sample-page/src/pages/sample.js" /* webpackChunkName: "component---plugins-gatsby-theme-sample-page-src-pages-sample-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-preview-js": () => import("./../../../src/templates/blog-preview.js" /* webpackChunkName: "component---src-templates-blog-preview-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

